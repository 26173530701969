import React from "react";
import Logo from "../Assets/Img/f_logo.png";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import Playstore from "./PlayStore";
import AppStore from "./AppStore";

export default function Footer() {
  return (
    <footer className="relative bg-f-primary pt-8 pb-6">
      <div
        className="container mx-auto pl-10 pr-10 p-16"
        style={{ maxWidth: "1400px", margin: "0 auto" }}
      >
        <div className="flex flex-wrap text-left lg:text-left">
          <div className="w-full lg:w-3/12 lg:px-4">
            <a href="./">
              <img src={Logo} alt="Logo" />
            </a>
            <div className="lg:mb-0 mb-6 center">
              <div className="flex flex-wrap space-x-2">
                <SocialIcon
                  url="https://chat.whatsapp.com/FrnjSu2jjA9LVIHjGxThqh"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://twitter.com/FAMEriseUP"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://www.linkedin.com/company/algofame"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://t.me/algoFAME"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://www.facebook.com/AlgorandFAME"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
              </div>
              <div className="flex flex-wrap space-x-2">
                <SocialIcon
                  url="https://youtube.com/@AlgoFame"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://medium.com/@fameapp"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://www.instagram.com/algofame/"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://github.com/ALGOFAME"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
                <SocialIcon
                  url="https://discord.gg/EdktE8ZwYe"
                  bgColor="#C9C9C9"
                  style={{ width: "30px", height: "30px", marginTop: "15px" }}
                />
              </div>
            </div>
          </div>
          <div className="w-full lg:w-3/12 lg:px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-12/12">
                <span
                  className="mb-2 text-white"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "31px",
                    letterSpacing: "0.44999998807907104px",
                    textAlign: "left",
                  }}
                >
                  The ALGOFAME DEVELOPMENT Family
                </span>
                <p
                  style={{
                    color: "#dadada",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "26px",
                    letterSpacing: "0.375px",
                    textAlign: "left",
                  }}
                >
                  We are agronomists, devs, builders, philosophers from
                  different continents, coming together to achieve a common
                  goal: Making a difference by empowering farmers.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-6/12 lg:px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-5/12 mb-6">
                <span
                  className="mb-2 text-white"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "31px",
                    letterSpacing: "0.44999998807907104px",
                    textAlign: "left",
                  }}
                >
                  Privacy and terms
                </span>
                <ul
                  style={{
                    color: "#dadada",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "26px",
                    letterSpacing: "0.375px",
                    textAlign: "left",
                  }}
                >
                  <li>
                    <NavLink to="/termsofservice">Terms of Service</NavLink>
                  </li>
                  <li>
                    <a href="">Privacy Policy</a>
                  </li>
                  {/* <li>
                    <a href={File} download>
                      Lite Paper 1.2
                    </a>
                  </li> */}
                </ul>
                <div className="lg:block hidden">
                  <div className="mb-3 mt-5">
                    <AppStore />
                  </div>

                  <Playstore />
                </div>
              </div>
              <div className="w-full lg:w-7/12">
                <span
                  className="mb-2 text-white"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "31px",
                    letterSpacing: "0.44999998807907104px",
                    textAlign: "left",
                  }}
                >
                  Contact
                </span>
                <p
                  style={{
                    color: "#dadada",
                    fontSize: "15px",
                    fontWeight: "400",
                    lineHeight: "26px",
                    letterSpacing: "0.375px",
                    textAlign: "left",
                  }}
                >
                  <a href="mailto:contact@algofame.org">contact@algofame.org</a>
                  <p
                    style={{
                      color: "#dadada",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "26px",
                      letterSpacing: "0.375px",
                      textAlign: "left",
                    }}
                  >
                    ALGOFAME DEVELOPMENT,
                  </p>
                  <p
                    style={{
                      color: "#dadada",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "26px",
                      letterSpacing: "0.375px",
                      textAlign: "left",
                    }}
                  >
                    {" "}
                    7 RUE CAMILLE CLAUDEL 28630 LE COUDRAY.
                  </p>
                  <p
                    style={{
                      color: "#dadada",
                      fontSize: "15px",
                      fontWeight: "400",
                      lineHeight: "26px",
                      letterSpacing: "0.375px",
                      textAlign: "left",
                    }}
                  >
                    société par actions simplifiée, immatriculée au RCS de
                    Chartres sous le N° 918151655 dont le siège social est sis à
                    CHARTRES
                  </p>
                  <div className="lg:hidden">
                    <div className="mb-3 mt-5">
                      <AppStore />
                    </div>

                    <Playstore />
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
